import '@operato/i18n/ox-i18n.js'

import { html } from 'lit'
import { customElement, query } from 'lit/decorators.js'

import { i18next } from '@operato/i18n'

import { AbstractSign } from '../../components/abstract-sign'
import { generatePasswordPatternHelp, generatePasswordPatternRegExp } from '../../utils/password-rule'

@customElement('auth-signup')
export class AuthSignup extends AbstractSign {
  private passwordPattern?: string
  private passwordHelp?: string

  get pageName() {
    return 'sign up'
  }

  get actionUrl() {
    return '/auth/signup'
  }

  @query('#confirm-password') confirmPass!: HTMLInputElement

  get formfields() {
    const { username = '', name = '', email = '' } = this.data || {}

    // .validationMessage=${this.passwordHelp || ''}
    // .validationMessage=${String(i18next.t('text.passwords do not match'))}

    return html`
      <div class="field">
        <md-filled-text-field
          name="username"
          type="text"
          label=${String(i18next.t('field.username'))}
          .value=${username}
          required
          ><md-icon slot="leading-icon">badge</md-icon></md-filled-text-field
        >
      </div>
      <div class="field">
        <md-filled-text-field name="name" type="text" label=${String(i18next.t('field.name'))} .value=${name} required
          ><md-icon slot="leading-icon">id_card</md-icon></md-filled-text-field
        >
      </div>
      <div class="field">
        <md-filled-text-field
          name="email"
          type="email"
          label=${String(i18next.t('field.email'))}
          required
          .value=${email}
          autocapitalize="off"
          autocomplete="off"
          @input=${(e: Event) => {
            const target = e.target as HTMLInputElement
            if (target.validity.typeMismatch) {
              target.setCustomValidity(i18next.t('text.invalid-email'))
            } else {
              target.setCustomValidity('')
            }
          }}
          ><md-icon slot="leading-icon">mail</md-icon></md-filled-text-field
        >
      </div>
      <div class="field">
        <md-filled-text-field
          name="password"
          type="password"
          label=${String(i18next.t('field.password'))}
          pattern=${this.passwordPattern || ''}
          supporting-text=${this.passwordHelp || ''}
          required
          autocomplete="off"
          @input=${e => {
            var val = e.target.value
            this.confirmPass.setAttribute('pattern', val.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '[$&]'))
          }}
        >
          ><md-icon slot="leading-icon">password</md-icon></md-filled-text-field
        >
      </div>
      <div class="field">
        <md-filled-text-field
          id="confirm-password"
          name="confirm-password"
          type="password"
          autocomplete="off"
          label=${String(i18next.t('field.confirm password'))}
          required
        >
          ><md-icon slot="leading-icon">password</md-icon></md-filled-text-field
        >
      </div>
      <md-elevated-button class="ui button" @click=${e => this._onSubmit(e)}>
        <ox-i18n msgid="field.${this.pageName}"></ox-i18n>
      </md-elevated-button>
    `
  }

  languageUpdated() {
    this.passwordPattern = generatePasswordPatternRegExp(this.data.passwordRule).source
    this.passwordHelp = generatePasswordPatternHelp(this.data.passwordRule)
  }

  get links() {
    return html`
      <a class="link" href="/auth/signin">
        <md-text-button><ox-i18n msgid="field.sign in"></ox-i18n></md-text-button>
      </a>
    `
  }
}
