import '@material/web/button/filled-button.js'
import '@material/web/button/outlined-button.js'
import '@material/web/icon/icon.js'
import { styles as MDTypeScaleStyles } from '@material/web/typography/md-typescale-styles'

import { css, html, nothing, LitElement } from 'lit'
import { render } from 'lit-html'
import { customElement, property, state } from 'lit/decorators.js'
import { ScrollbarStyles } from '@operato/styles'

const TYPES_ICON = {
  success: 'verified',
  error: 'error',
  warning: 'warning',
  info: 'info',
  question: 'question_mark'
}

/**
 * The `ox-prompt` custom element represents a modal popup that provides information or options for the user, such as confirmation or cancellation.
 */
@customElement('ox-prompt')
export class OxPrompt extends LitElement {
  static styles = [
    MDTypeScaleStyles,
    ScrollbarStyles,
    css`
      :host {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: var(--ox-prompt-gap, var(--spacing-medium));
        background-color: var(--ox-popup-list-background-color, var(--md-sys-color-surface));
        z-index: 100;
        padding: var(--ox-prompt-container-padding, var(--spacing-medium));
        box-shadow: var(--ox-prompt-container-box-shadow, 2px 3px 10px 5px rgba(0, 0, 0, 0.15));
        border-radius: var(--ox-prompt-container-border-radius, var(--md-sys-shape-corner-medium));
        box-sizing: border-box;
        min-width: 300;
        line-height: initial;
        text-align: initial;
      }

      :host([active]) {
        display: block;
      }

      :host(*:focus) {
        outline: none;
      }

      [titler] {
        padding: var(--ox-prompt-title-padding, var(--spacing-medium));
        padding-bottom: 0;
        color: var(--ox-prompt-title-color, var(--md-sys-color-primary));
        font-size: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500));
        font-weight: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500));
      }

      [content] {
        display: flex;
        flex-direction: column;
        gap: var(--ox-prompt-content-gap, var(--spacing-medium));
        padding: var(--ox-prompt-content-padding, var(--spacing-medium));
        color: var(--ox-prompt-body-color, var(--md-sys-color-on-surface));
        word-break: keep-all;

        md-icon {
          align-self: center;
          --md-icon-size: var(--icon-size-huge);
          color: var(--ox-prompt-body-color-variant, var(--md-sys-color-primary));
        }
      }

      [content].warning md-icon {
        color: var(--status-warning-color, #ee8d03);
      }

      [content].error md-icon {
        color: var(--md-sys-color-error, var(--md-sys-color-error));
      }

      [content].info md-icon {
        color: var(--status-info-color, #398ace);
      }

      [content].success md-icon {
        color: var(--status-success-color, #35a24a);
      }

      [buttons] {
        display: flex;
        border-top: 1px solid var(--md-sys-color-surface-variant);
        gap: var(--ox-prompt-buttons-spacing, var(--spacing-large));
        padding: var(--ox-prompt-buttons-padding, var(--spacing-medium));
        padding-top: var(--spacing-large);
        justify-content: center;
      }

      #confirm {
        --md-filled-button-container-color: var(--md-sys-color-primary);
        --md-filled-button-label-text-color: var(--md-sys-color-on-primary);
        --md-filled-button-label-text-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        --md-filled-button-container-height: var(--form-element-height-medium);
        --md-filled-button-container-shape: var(--md-sys-shape-corner-small);
        --md-filled-button-leading-space: var(--spacing-large);
        --md-filled-button-trailing-space: var(--spacing-large);
      }

      #cancel {
        --md-outlined-button-container-color: var(--md-sys-color-surface-variant);
        --md-outlined-button-label-text-color: var(--md-sys-color-on-surface-variant);
        --md-outlined-button-label-text-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        --md-outlined-button-container-height: var(--form-element-height-medium);
        --md-outlined-button-container-shape: var(--md-sys-shape-corner-small);
        --md-outlined-button-leading-space: var(--spacing-large);
        --md-outlined-button-trailing-space: var(--spacing-large);
      }
    `
  ]

  /**
   * Specifies the type of the popup. Possible values are 'success', 'error', 'warning', 'info', 'question'.
   */
  @property({ type: String }) type?: 'success' | 'error' | 'warning' | 'info' | 'question'

  /**
   * Specifies the icon of the popup.
   */
  @property({ type: String }) icon?: string

  /**
   * Specifies the title of the popup.
   */
  @property({ type: String }) titler?: string = ''

  /**
   * Specifies the text content of the popup.
   */
  @property({ type: String }) text?: string

  /**
   * Specifies the footer (additional information at the bottom) of the popup.
   */
  @property({ type: String }) footer?: string

  /**
   * Determines whether the popup is displayed as a toast.
   */
  @property({ type: Boolean }) toast?: boolean

  /**
   * Specifies settings for the confirmation button.
   */
  @property({ type: Object }) confirmButton?: { text: string; color?: string }

  /**
   * Specifies settings for the cancel button.
   */
  @property({ type: Object }) cancelButton?: { text: string; color?: string }

  /**
   * Prevents the popup from closing when it loses focus (blur event).
   */
  @property({ type: Boolean, attribute: 'prevent-close-on-blur' }) preventCloseOnBlur: boolean = false

  /**
   * A callback function called when the popup is closed, providing the result of the user's interaction.
   */
  @property({ type: Object }) callback?: (result: { value: boolean }) => void

  @state() _parent?: Element

  private resolveFn: ((value: boolean) => void) | null = null

  render() {
    return html`
      ${this.titler ? html` <div titler class="md-typescale-title-large">${this.titler}</div> ` : nothing}
      <div content class="${this.type || 'info'} md-typescale-body-large">
        ${this.icon || this.type
          ? html` <md-icon icon>${this.icon || TYPES_ICON[this.type || 'info']}</md-icon> `
          : nothing}
        ${this.text ? html` <div text>${this.text}</div> ` : nothing}
        <slot> </slot>
        ${this.footer ? html` <div footer class="md-typescale-body-large">${this.footer}</div> ` : nothing}
      </div>
      <div buttons>
        ${this.confirmButton
          ? html`
              <md-filled-button id="confirm" type="button" @click=${(e: Event) => this.onConfirm()}
                >${this.confirmButton?.text}</md-filled-button
              >
            `
          : nothing}
        ${this.cancelButton
          ? html`
              <md-outlined-button id="cancel" type="button" @click=${(e: Event) => this.onCancel()}
                >${this.cancelButton?.text}</md-outlined-button
              >
            `
          : nothing}
      </div>
    `
  }

  resolve(result: boolean) {
    if (this.resolveFn) {
      this.resolveFn(result)
      this.resolveFn = null
    }
  }

  /**
   * Function called when the confirm button is clicked.
   */
  onConfirm() {
    this.resolve(true)
    this.close()
  }

  /**
   * Function called when the cancel button is clicked.
   */
  onCancel() {
    this.resolve(false)
    this.close()
  }

  protected _onfocusout: (e: FocusEvent) => void = function (this: OxPrompt, e: FocusEvent) {
    const to = e.relatedTarget as HTMLElement

    if (!this.contains(to)) {
      /* 분명히 내 범위가 아닌 엘리먼트로 포커스가 옮겨졌다면, ox-prompt은 닫혀야 한다. */
      // @ts-ignore for debug
      if (this.preventCloseOnBlur || window.POPUP_DEBUG) {
        return
      }

      this.resolve(false)
      this.close()
    }
  }.bind(this)

  protected _onkeydown: (e: KeyboardEvent) => void = function (this: OxPrompt, e: KeyboardEvent) {
    e.stopPropagation()

    switch (e.key) {
      case 'Esc': // for IE/Edge
      case 'Escape':
        this.resolve(false)
        this.close()
        break
    }
  }.bind(this)

  protected _onkeyup: (e: KeyboardEvent) => void = function (this: OxPrompt, e: KeyboardEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _onmouseup: (e: MouseEvent) => void = function (this: OxPrompt, e: MouseEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _onmousedown: (e: MouseEvent) => void = function (this: OxPrompt, e: MouseEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _oncontextmenu: (e: Event) => void = function (this: OxPrompt, e: Event) {
    e.stopPropagation()
  }.bind(this)

  protected _onclick: (e: MouseEvent) => void = function (this: OxPrompt, e: MouseEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _onclose: (e: Event) => void = function (this: OxPrompt, e: Event) {
    this.resolve(false)
    this.close()
  }.bind(this)

  protected _oncollapse: (e: Event) => void = function (this: OxPrompt, e: Event) {
    e.stopPropagation()

    this.resolve(false)
    this.close()
  }.bind(this)

  protected _onwindowblur: (e: Event) => void = function (this: OxPrompt, e: Event) {
    // @ts-ignore for debug
    if (this.preventCloseOnBlur || window.POPUP_DEBUG) {
      return
    }

    this.resolve(false)
    this.close()
  }.bind(this)

  connectedCallback() {
    super.connectedCallback()

    this.addEventListener('focusout', this._onfocusout)
    this.addEventListener('keydown', this._onkeydown)
    this.addEventListener('keyup', this._onkeyup)
    this.addEventListener('click', this._onclick)
    this.addEventListener('mouseup', this._onmouseup)
    this.addEventListener('mousedown', this._onmousedown)
    this.addEventListener('contextmenu', this._oncontextmenu)
    this.addEventListener('ox-close', this._onclose)
    this.addEventListener('ox-collapse', this._oncollapse)

    this.setAttribute('tabindex', '0') // make this element focusable
    this.guaranteeFocus()
  }

  /**
   * Static method to open the `ox-prompt` popup.
   * @param {object} options - An object containing popup options.
   * @param {unknown} [options.template] - An optional content template to render inside the popup.
   * @param {'success' | 'error' | 'warning' | 'info' | 'question'} [options.type] - The type of the popup, which can be one of: 'success', 'error', 'warning', 'info', 'question'.
   * @param {string} [options.icon] - The icon to be displayed in the popup header.
   * @param {string} [options.title] - The title to be displayed in the popup header.
   * @param {string} [options.text] - The main text content of the popup.
   * @param {string} [options.footer] - Additional information to be displayed at the bottom of the popup.
   * @param {object} [options.confirmButton] - Configuration for the confirmation button in the popup.
   * @param {string} options.confirmButton.text - The text to be displayed on the confirmation button.
   * @param {string} [options.confirmButton.color] - The color of the confirmation button (CSS color).
   * @param {object} [options.cancelButton] - Configuration for the cancel button in the popup.
   * @param {string} options.cancelButton.text - The text to be displayed on the cancel button.
   * @param {string} [options.cancelButton.color] - The color of the cancel button (CSS color).
   * @param {number} [options.top] - The top position of the popup (in pixels).
   * @param {number} [options.left] - The left position of the popup (in pixels).
   * @param {number} [options.right] - The right position of the popup (in pixels).
   * @param {number} [options.bottom] - The bottom position of the popup (in pixels).
   * @param {string} [options.width] - The maximum width of the popup (CSS string).
   * @param {string} [options.height] - The maximum height of the popup (CSS string).
   * @param {Element | null} [options.parent] - The parent element to which the popup should be attached. If not provided, it will be attached to the document body.
   * @param {boolean} [options.preventCloseOnBlur] - Prevents the popup from closing when it loses focus (blur event).
   * @param {(result: { value: boolean }) => void} [options.callback] - A callback function that will be invoked when the user interacts with the popup, providing the result of the interaction.
   * @returns {Promise<boolean>} A Promise that resolves based on user interaction with the popup.
   */
  public static async open({
    template,
    type,
    icon,
    title,
    text,
    footer,
    confirmButton,
    cancelButton,
    top,
    left,
    right,
    bottom,
    width,
    height,
    parent,
    preventCloseOnBlur,
    callback
  }: {
    template?: unknown
    type?: 'success' | 'error' | 'warning' | 'info' | 'question'
    icon?: string
    title?: string
    text?: string
    footer?: string
    confirmButton?: { text: string; color?: string }
    cancelButton?: { text: string; color?: string }
    top?: number
    left?: number
    right?: number
    bottom?: number
    width?: string
    height?: string
    parent?: Element | null
    preventCloseOnBlur?: boolean
    callback?: (result: { value: boolean }) => void
  }): Promise<boolean> {
    const owner = parent || document.body
    const target = document.createElement('ox-prompt') as OxPrompt

    target.type = type
    target.icon = icon
    target.text = text
    target.titler = title
    target.footer = footer
    target.confirmButton = confirmButton
    target.cancelButton = cancelButton
    target.preventCloseOnBlur = !!preventCloseOnBlur

    render(template, target)

    target._parent = owner
    owner.appendChild(target)

    const result = await target.open({ top, left, right, bottom, width, height })

    if (callback) {
      await callback.call(null, { value: result })
    }

    return result
  }

  /**
   * Opens the popup with specified position and dimensions.
   * @param {object} options - An object specifying the position and dimensions of the popup.
   * @param {number} [options.left] - The left position of the popup (in pixels). If not provided, the popup will be horizontally centered.
   * @param {number} [options.top] - The top position of the popup (in pixels). If not provided, the popup will be vertically centered.
   * @param {number} [options.right] - The right position of the popup (in pixels). Overrides 'left' if both 'left' and 'right' are provided.
   * @param {number} [options.bottom] - The bottom position of the popup (in pixels). Overrides 'top' if both 'top' and 'bottom' are provided.
   * @param {string} [options.width] - The maximum width of the popup (CSS string). If not provided, no width restriction is applied.
   * @param {string} [options.height] - The maximum height of the popup (CSS string). If not provided, no height restriction is applied.
   * @param {boolean} [options.silent=false] - Determines whether to focus the popup automatically (true) or not (false).
   * @returns {Promise<boolean>} A Promise that resolves based on user interaction with the popup.
   */
  open({
    left,
    top,
    right,
    bottom,
    width,
    height,
    silent = false
  }: {
    left?: number
    top?: number
    right?: number
    bottom?: number
    width?: string
    height?: string
    silent?: boolean
  }): Promise<boolean> {
    if (width) {
      this.style.maxWidth = width
      this.style.overflowX = 'auto'
    }

    if (height) {
      this.style.maxHeight = height
      this.style.overflowY = 'auto'
    }

    if (left === undefined && top === undefined && right === undefined && bottom === undefined) {
      this.style.left = '50%'
      this.style.top = '50%'
      this.style.transform = 'translateX(-50%) translateY(-50%)'
    } else {
      if (left !== undefined) this.style.left = `${left}px`
      if (top !== undefined) this.style.top = `${top}px`
      if (right !== undefined) this.style.right = `${right}px`
      if (bottom !== undefined) this.style.bottom = `${bottom}px`
    }

    this.setAttribute('active', '')

    // adjust popup position
    requestAnimationFrame(() => {
      const vh = document.body.clientHeight
      const vw = document.body.clientWidth

      var bounding = this.getBoundingClientRect()

      var h = bounding.height
      var w = bounding.width
      var t = bounding.top
      var l = bounding.left

      // If the popup is too large, it will cause overflow scrolling.
      if (vh < h) {
        this.style.height = `${Math.min(Math.max(Math.floor((vh * 2) / 3), vh - (t + 20)), vh)}px`
        this.style.overflow = 'auto'
        h = vh
      }

      if (vw < w) {
        this.style.width = `${Math.min(Math.max(Math.floor((vw * 2) / 3), vw - (l + 20)), vw)}px`
        this.style.overflow = 'auto'
        w = vw
      }

      // To prevent pop-ups from crossing screen boundaries, use the
      const computedStyle = getComputedStyle(this)

      if (t < 0) {
        this.style.top = `calc(${computedStyle.top} + ${t}px)`
        this.style.bottom = ''
      } else if (vh <= t + h) {
        this.style.top = `calc(${computedStyle.top} - ${t + h - vh}px)`
        this.style.bottom = ''
      }

      if (l < 0) {
        this.style.left = `calc(${computedStyle.left} + ${l}px)`
        this.style.right = ''
      } else if (vw < l + w) {
        this.style.left = `calc(${computedStyle.left} - ${l + w - vw}px)`
        this.style.right = ''
      }
    })

    // auto focusing
    setTimeout(() => {
      !silent && this.guaranteeFocus()
    }, 100)

    /* When the window is out of focus, all pop-ups should disappear. */
    window.addEventListener('blur', this._onwindowblur)

    return new Promise(resolve => {
      this.resolveFn = resolve
    })
  }

  guaranteeFocus(target?: HTMLElement) {
    const focusible = (target || this.renderRoot)?.querySelector(
      ':scope > button, :scope > [href], :scope > input, :scope > select, :scope > textarea, :scope > [tabindex]:not([tabindex="-1"]), :scope [type="button"]'
    )

    if (focusible) {
      ;(focusible as HTMLElement).focus()
    } else {
      this.focus()
    }
  }

  /**
   * Closes the popup.
   */
  close() {
    this.removeAttribute('active')

    window.removeEventListener('blur', this._onwindowblur)

    if (this._parent) {
      /* this case is when the popup is opened by OxPrompt.open(...) */
      this.removeEventListener('focusout', this._onfocusout)
      this.removeEventListener('keydown', this._onkeydown)
      this.removeEventListener('keyup', this._onkeyup)
      this.removeEventListener('click', this._onclick)
      this.removeEventListener('ox-close', this._onclose)
      this.removeEventListener('ox-collapse', this._oncollapse)
      this.removeEventListener('mouseup', this._onmouseup)
      this.removeEventListener('mousedown', this._onmousedown)
      this.removeEventListener('contextmenu', this._oncontextmenu)

      this._parent.removeChild(this)
      delete this._parent
    }
  }
}
